import React, { useEffect, useState } from 'react';

import cn from 'classnames';
import { config, useTransition } from 'react-spring';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Section from '../../../design_system/section/section';
import Carousel from '../../../design_system/carousel/carousel';
import CounterAdvantageItem from './counter_advantage_item/counter_advantage_item';

import counterAdvantagesData from './counter_advantages_data';

import classes from './counter_advantages.module.css';

const CounterAdvantages = () => {
	const showAsCarousel = useMediaQuery('(max-width: 850px)', { defaultMatches: true });
	return (
		<Section
			setVisibleOnScroll
			withClassName={cn(classes.section, showAsCarousel && classes.sectionCarousel)}
		>
			{({ showSection }) => (
				<AdvantagesList {...{ showAsCarousel, showSection }} />
			)}
		</Section>
	)
};

const AdvantagesList = ({ showAsCarousel, showSection }) => {
	if (showAsCarousel) {
		return <AdvantagesCarousel />
	}
	return <AdvantagesTransitions {...{ showSection }} />
}

const AdvantagesCarousel = () => (
	<div className={classes.carouselContainer}>
		<Carousel
			centerMode
			centerPadding={0}
			rows={2}
			slidesPerRow={1}
			slidesToShow={1}
			dots={false}
			arrowClassName={classes.arrow}
		>
			{Object.entries(counterAdvantagesData).map(([id, advantage]) => (
				<div className={classes.inCarouselAdvantageContainer}>
					<CounterAdvantageItem
						className={classes.inCarouselItem}
						key={`advantage_${id}`}
						{...advantage}
					/>
				</div>
			))}
		</Carousel>
	</div>
);

const AdvantagesTransitions = ({ showSection }) => {
	const [items, setItems] = useState();
	useEffect(() => {
		if (showSection && !items) {
			setItems(Object.entries(counterAdvantagesData))
		} else if (!showSection && items) {
			setItems(null);
		}
	}, [showSection, items])
	const transitions = useTransition(items, entry => entry && entry[0], {
		unique: true,
		trail: 150,
		config: config.slow,
		from: {
			scale: 0.9,
			opacity: 0
		},
		enter: {
			scale: 1,
			opacity: 1
		},
		leave: {
			scale: 0.9,
			opacity: 0
		}
	});
	return (
		<div className={classes.advantagesContainer}>
			{transitions.map(({ item, props: { scale, ...springProps }, key }) => item && (
				<CounterAdvantageItem
					{...{ key }}
					{...item && item[1]}
					style={{
						transform: scale.interpolate(value => `scale3d(${value}, ${value}, ${value})`),
						...springProps
					}}
				/>
			))}
		</div>
	);
}

export default CounterAdvantages;
