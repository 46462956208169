import React from 'react';

import Section from '../../../design_system/section/section';
import Button from '../../../design_system/button/button';
import OptimisedImage from '../../../small_views/optimised_image/optimised_image';

import CounterPackagePicture from '../../../../assets/images/pages/thecounter/counter_actions_package_section/package.png';
import CounterPackagePictureLowDef from '../../../../assets/images/pages/thecounter/counter_actions_package_section/package_low_def.png';

import classes from './counter_actions_package_section.module.css';

const CounterBigPictureSection = () => (
	<Section withClassName={classes.container}>
		<div className={classes.content}>
			<div className={classes.titleButtonContainer}>
				<div className={classes.title}>
					{'Posez-le sur votre matériel,'}
					<br />
					{"il s'occupe du reste."}
				</div>
				<div className={classes.actions}>
					<Button
						color="primary"
						className={classes.button}
						variant="contained"
					>
						{'Demander un devis gratuit >'}
					</Button>
				</div>
			</div>
			<div className={classes.counterPictureContainer}>
				<OptimisedImage
					className={classes.counterPicture}
					normal={CounterPackagePicture}
					lowDef={CounterPackagePictureLowDef}
					alt="Karnott Package"
					additionnalFilter="drop-shadow(3px 9px 10px rgba(0, 0, 0, 0.4))"
				/>
			</div>
		</div>
	</Section>
);

export default CounterBigPictureSection;
