import React from 'react'

import cn from 'classnames'
import { animated } from 'react-spring'

import classes from './counter_advantage_item.module.css'

const CounterAdvantageItem = ({
	className,
	icon: Icon,
	title,
	description,
	...other
}) => (
	<animated.div className={cn(classes.container, className)} {...other}>
		<div className={classes.icon}>
			<Icon />
		</div>
		<span className={classes.name}>{title}</span>
		<span className={classes.description}>{description}</span>
	</animated.div>
)

export default CounterAdvantageItem
