import React from 'react';

import HelmetLayout from '../../components/layouts/helmet_layout/helmet_layout';
import MainLayout from '../../components/layouts/main_layout/main_layout';
import WithGradientLayout from '../../components/layouts/with_gradient_layout/with_gradient_layout';

import CounterBigPictureSection from '../../components/solutions/thecounter/counter_big_picture_section/counter_big_picture_section';
import CounterAdvantages from '../../components/solutions/thecounter/counter_advantages/counter_advantages';
import CounterActionsPackageSection from '../../components/solutions/thecounter/counter_actions_package_section/counter_actions_package_section';

const TheCounter = () => (
	<HelmetLayout>
		<MainLayout>
			<WithGradientLayout>
				<CounterBigPictureSection />
				<CounterAdvantages />
				<CounterActionsPackageSection />
			</WithGradientLayout>
		</MainLayout>
	</HelmetLayout>
);

export default TheCounter;
