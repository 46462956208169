import React from 'react';

import Section from '../../../design_system/section/section';
import PlayVideo from '../../../small_views/play_video/play_video';
import OptimisedImage from '../../../small_views/optimised_image/optimised_image';

import CounterPicture from '../../../../assets/images/pages/thecounter/counter_big_picture_section/counter.png';
import CounterPictureLowDef from '../../../../assets/images/pages/thecounter/counter_big_picture_section/counter_low_def.png';

import classes from './counter_big_picture_section.module.css';

const CounterBigPictureSection = () => (
	<Section withClassName={classes.container}>
		<div className={classes.content}>
			<div className={classes.titlePlayVideoContainer}>
				<div className={classes.title}>
					{'Un compteur connecté,'}
					<br />
					{'ultra performant.'}
				</div>
				<PlayVideo
					title="Voir la vidéo"
					duration="2:34"
					pulseColor="light"
					changeTextColorOnHover="primary"
				/>
			</div>
			<div className={classes.counterPictureContainer}>
				<OptimisedImage
					className={classes.counterPicture}
					alt="Compteur Karnott"
					normal={CounterPicture}
					lowDef={CounterPictureLowDef}
					additionnalFilter="drop-shadow(3px 9px 10px rgba(0, 0, 0, 0.4))"
				/>
			</div>
		</div>
	</Section>
);

export default CounterBigPictureSection;
