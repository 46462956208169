import TechnologyConcentrateIcon from '../../../../assets/images/pages/thecounter/counter_advantages/bijoutechnologie.svg';
import GeolocalisationIcon from '../../../../assets/images/pages/thecounter/counter_advantages/geolocalisation.svg';
import WaterResistantIcon from '../../../../assets/images/pages/thecounter/counter_advantages/robuste.svg';
import RealtimeDataIcon from '../../../../assets/images/pages/thecounter/counter_advantages/tempsreel.svg';
import NoConnectionIcon from '../../../../assets/images/pages/thecounter/counter_advantages/zerobranchement.svg';
import IndependantIcon from '../../../../assets/images/pages/thecounter/counter_advantages/autonome.svg';

const counterAdvantagesData = Object.freeze({
	technologyConcentrate: {
		icon: TechnologyConcentrateIcon,
		title: 'Un concentré de technologie',
		description: `Les temps, les surfaces, les kilomètres,
 les angles. Grâce à ses nombreux capteurs,
Karnott calcule tout`
	},
	geolocalisation: {
		icon: GeolocalisationIcon,
		title: 'Géolocalisation',
		description: `Quel que soit le nombre, en activité
ou non utilisé, votre flotte de matériels
est localisée et sous contrôle.`
	},
	waterResistant: {
		icon: WaterResistantIcon,
		title: 'Robuste et résistant',
		description: `Conçu pour les secteurs agricole
et viticole, votre Karnott vous accompagne
quelle que soit la météo.`
	},
	realtimeData: {
		icon: RealtimeDataIcon,
		title: 'Données en temps réel',
		description: `1 ou 10 Karnott, pas de problème, vos
données sont automatiquement transférées
dans votre application et disponibles n’importe
où, n’importe quand.`
	},
	noConnection: {
		icon: NoConnectionIcon,
		title: 'Zéro branchement',
		description: `Doté d’un aimant puissant, il vous suffit
de poser votre Karnott sur votre matériel.
Aucune intervention n’est nécessaire,
il s’active aux vibrations.`
	},
	independant: {
		icon: IndependantIcon,
		title: 'Autonome et indépendant',
		description: `Karnott est universel et s’adapte
à tous les matériels. De plus,
sa batterie rechargeable vous assure
une utilisation optimale.`
	}
});

export default counterAdvantagesData;
